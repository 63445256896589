<template>
    <div :class="_isMobile() ? '' : 'app-wrapper'">
        <div class="app-header pd-all" v-if="!_isMobile()">
            <div class="">
                <div class="logo">
                    <img src="../assets/logo.png" alt="" />
                </div>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    text-color="#1B2645"
                    active-text-color="#1A39FF"
                    style="display: flex"
                >
                    <el-menu-item index="1" @click="toggle(1, '/homeIndex')"
                        >首<span style="opacity: 0">首页</span>页</el-menu-item
                    >
                    <el-menu-item
                        index="2"
                        @click="toggle(2, '/productPresentation')"
                        >产品介绍</el-menu-item
                    >
                    <el-menu-item
                        index="3"
                        @click="toggle(3, '/companyProfile')"
                        >企业介绍</el-menu-item
                    >
                    <el-menu-item index="4" @click="toggle(4, '/newsAndTrends')"
                        >新闻动态</el-menu-item
                    >
                    <el-menu-item index="5" @click="toggle(5, '/contactUs')"
                        >联系我们</el-menu-item
                    >
                </el-menu>
            </div>
        </div>
        <div v-else class="m-app-header pd-all">
            <div class="">
                <img src="../assets/logo.png" alt="" />
                <el-dropdown trigger="click" @command="toggleDrop">
                    <span class="el-dropdown-link">
                        <i class="el-icon-s-fold el-icon--right title-i"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">首页</el-dropdown-item>
                        <el-dropdown-item command="2"
                            >产品介绍</el-dropdown-item
                        >
                        <el-dropdown-item command="3"
                            >企业介绍</el-dropdown-item
                        >
                        <el-dropdown-item command="4"
                            >新闻动态</el-dropdown-item
                        >
                        <el-dropdown-item command="5"
                            >联系我们</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="main-container" :class="_isMobile() ? 'pt66' : 'pt99'">
            <div>
                <keep-alive>
                    <router-view :key="key" v-if="$route.meta.keepAlive" />
                </keep-alive>
                <router-view :key="key" v-if="!$route.meta.keepAlive" />
            </div>
        </div>
        <div class="footer pd-all">
            <div>
                <div>海南明势网络科技有限公司版权所有</div>
                <div>
                    <!-- <span
                        >地址：×××××××××××××××××××××××××
                    </span>
                    <span>电话：××××××××××××××××××</span>
                    <span>邮箱：××××××××××××××××××</span> -->
                    <span> <span class="beian">  <a href="http://beian.miit.gov.cn/">琼ICP备 2021009534 号</a> </span></span>
                </div>
                <div style="margin:0 auto; padding:20px 0;">
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46900502000281" style="color:rgba(255, 255, 255, 0.62);font-size:16px;    display: flex;"><img src="../assets/gabatb.png" style="margin-right:5px;"/>琼公网安备 46900502000281号</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LayOut",
    components: {},
    watch: {
        $route: {
            handler(val) {
                this.getActiveIndex(val.path)
            },
            deep: true,
        },
    },
    data() {
        return {
            activeIndex: "1",
        }
    },
    created() {
        this.getActiveIndex(this.$route.path)
    },
    computed: {
        key() {
            return this.$route.path
        },
    },
    methods: {
        getActiveIndex(e) {
            switch (e) {
                case "/productPresentation":
                    this.activeIndex = "2"
                    break
                case "/companyProfile":
                    this.activeIndex = "3"
                    break
                case "/newsAndTrends":
                    this.activeIndex = "4"
                    break
                case "/contactUs":
                    this.activeIndex = "5"
                    break
                default:
                    break
            }
        },
        toggle(e, path) {
            console.log(e)
            if (this.$route.path === "/homeIndex") {
                this.$store.dispatch("app/toggleSideBar", e)
            } else {
                this.$router.push({
                    path: path,
                    query: {},
                })
            }
        },
        toggleDrop(command) {
            if (this.$route.path === "/homeIndex") {
                this.$store.dispatch("app/toggleSideBar", command)
            } else {
                switch (command) {
                    case "1":
                        this.$router.push({
                            path: "/homeIndex",
                            query: {},
                        })
                        break
                    case "2":
                        this.$router.push({
                            path: "/productPresentation",
                            query: {},
                        })
                        break
                    case "3":
                        this.$router.push({
                            path: "/companyProfile",
                            query: {},
                        })
                        break
                    case "4":
                        this.$router.push({
                            path: "/newsAndTrends",
                            query: {},
                        })
                        break
                    case "5":
                        this.$router.push({
                            path: "/contactUs",
                            query: {},
                        })
                        break
                    default:
                        break
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import "./style.scss";
</style>
<style scoped lang="scss">
.app-header {
    /* box-shadow: 2px 0px 10px rgb(0 0 0 / 20%); */
}
.beian{
    margin-left: 0px;
    >a:hover{
        color: #ffffff;
    }
}
</style>
