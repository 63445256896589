const getters = {
    sidebar: state => state.app.sidebar,
    device: state => state.app.device,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    brhLevel: state => state.base.brhLevel,
    termchType: state => state.base.termchType,
    terminalUses: state => state.base.terminalUses,
    termType: state => state.base.termType,
    tremStatus: state => state.base.tremStatus,
    termReachStaus: state => state.base.termReachStaus,
    termActiveStaus: state => state.base.termActiveStaus,
    permission_routes: state => state.permission.routes,
    userPrivileges: state => state.menu.userPrivileges
};
export default getters

