import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
import Layout from "@/layout";
// noCache;
export const constantRoutes = [
    //    {
    //        path: "/login",
    //        menuAddr: "/login",
    //        component: () => import("@/views/loginIn/index"),
    //        hidden: true
    //    },
    //    {
    //        path: "/404",
    //        menuAddr: "/404",
    //        redirect: "/dashboard"
    //    },
    {
        path: "/",
        menuAddr: "/homeIndex",
        component: Layout,
        redirect: "/homeIndex",
        name: "明势-数字改变生活",
        meta: { title: "明势-数字改变生活", icon: "el-icon-s-home" },
        children: [
            {
                path: "homeIndex",
                name: "明势-数字改变生活",
                component: () => import("@/views/homeIndex/index"),
                meta: {
                    title: "明势-数字改变生活",
                    icon: "el-icon-s-home",
                    affix: true,
                },
            },
            {
                path: "productPresentation",
                name: "产品介绍",
                component: () => import("@/views/productPresentation/index"),
                meta: {
                    title: "产品介绍",
                    icon: "el-icon-s-home",
                    affix: true,
                },
            },
            {
                path: "companyProfile",
                name: "企业介绍",
                component: () => import("@/views/companyProfile/index"),
                meta: {
                    title: "企业介绍",
                    icon: "el-icon-s-home",
                    affix: true,
                },
            },
            {
                path: "newsAndTrends",
                name: "新闻动态",
                component: () => import("@/views/newsAndTrends/index"),
                meta: {
                    title: "新闻动态",
                    icon: "el-icon-s-home",
                    affix: true,
                },
            },
            {
                path: "contactUs",
                name: "联系我们",
                component: () => import("@/views/contactUs/index"),
                meta: {
                    title: "联系我们",
                    icon: "el-icon-s-home",
                    affix: true,
                },
            },
            {
                path: "newDetail",
                name: "新闻详情",
                component: () => import("@/views/newDetail/index"),
                meta: {
                    title: "新闻详情",
                    icon: "el-icon-s-home",
                    affix: true,
                },
            },
        ],
    },
    //    { path: "*", redirect: "/404", hidden: true }
];


const createRouter = () =>
    new Router({
        scrollBehavior: () => ({ y: 0 }),
        base: "/viewoms/",
        routes: constantRoutes,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
