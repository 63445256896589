import Cookies from "js-cookie";

const state = {
    sidebar: {
        opened: Cookies.get("sidebarStatus")
            ? !!+Cookies.get("sidebarStatus")
            : true,
        withoutAnimation: false,
    },
    device: "desktop",
    activeIndex: "1",
};

const mutations = {
    TOGGLE_SIDEBAR: (state, e) => {
        state.activeIndex = e;
        Cookies.set("activeIndex", e);
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set("sidebarStatus", 0);
        state.sidebar.opened = false;
        state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device;
    },
};

const actions = {
    toggleSideBar({ commit }, e) {
        commit("TOGGLE_SIDEBAR", e);
    },
    closeSideBar({ commit }, { withoutAnimation }) {
        commit("CLOSE_SIDEBAR", withoutAnimation);
    },
    toggleDevice({ commit }, device) {
        commit("TOGGLE_DEVICE", device);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
