import Vue from "vue";
import router from "./router";
import store from "./store";
import { Menu, Submenu, MenuItem, Carousel, CarouselItem,Dropdown,DropdownItem,DropdownMenu } from "element-ui";
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
import "@/permission"; // permission control
import App from "./App.vue";
import "@/styles/base.scss"; // global css
Vue.config.productionTip = false;
Vue.prototype._isMobile = function () {
    let flag =
        navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        ) == null
            ? false
            : true;
    return flag;
};
new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
});
